import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { appSettings } from '../../../../appsettings';

@Injectable()
export class UnopnListService {

    public controllerName: string = "signin";

    constructor(
        public http: HttpClient,
        private appSettings: appSettings
    ) { }

    public GetUnion() {
        //return this.http.get(this.appSettings.API_Config + this.controllerName + '/GetUnionList', null);
        return this.http.post(this.appSettings.API_Config + this.controllerName + '/GetUnionList', null);
    }

    public ReinitToken(data: any) {
        if (data) {
            return this.http.post(this.appSettings.API_Config + this.controllerName + '/ReinitToken', data);
        }
    }

}
