import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor, HttpHeaders,
    HttpResponse,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    // This method should be written in commonAPI service and called from there

    constructor(
        private router: Router
    ) {
    }

    public getCookie(cname: string): string {
        const name = cname + "=";
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }


    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        
        let changedRequest = request;
        // HttpHeader object immutable - copy values
        const headerSettings: { [name: string]: string | string[]; } = {};

        for (const key of request.headers.keys()) {
           headerSettings[key] = request.headers.getAll(key);
        }
        // For Development
        headerSettings['x-access-token'] = sessionStorage.getItem("x-access-token") ? sessionStorage.getItem("x-access-token") : "";  
        //headerSettings['content-Type'] = "application/json; charset=utf-8";
        headerSettings['Accept'] = "application/json";
        const newHeader = new HttpHeaders(headerSettings);
       
        changedRequest = request.clone({
            headers: newHeader
        });

        return next.handle(changedRequest)
            .pipe(catchError((err: any, caught) => {
                console.log(err)
                if (err instanceof HttpErrorResponse) {
                    if (err.status === 401) {
                        this.router.navigate(['/sessions/signin']);
                    }
                    return throwError(err);
                }
            }) as any);
        
    }
}