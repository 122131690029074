import { Component, OnInit, OnDestroy, AfterViewInit, ChangeDetectorRef } from "@angular/core";
import { NavigationService } from "../../../shared/services/navigation.service";
import { ThemeService } from "../../services/theme.service";
import { Subscription } from "rxjs";
import { ILayoutConf, LayoutService } from "../../services/layout.service";
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: "app-sidebar-side",
    templateUrl: "./sidebar-side.component.html"
})
export class SidebarSideComponent implements OnInit, OnDestroy, AfterViewInit {

    public menuItems: any[];
    public hasIconTypeMenuItem: boolean;
    public iconTypeMenuTitle: string;
    private menuItemsSub: Subscription;
    public layoutConf: ILayoutConf;
    public ProfilePicUrl: string = "";      
    public userName: string = "";

    constructor(
        private navService: NavigationService,
        public themeService: ThemeService,
        private layout: LayoutService,
        private cdr: ChangeDetectorRef,
        private router: Router
    ) { }

    ngOnInit() {
        this.userName = localStorage.getItem("ProfilePicUrl");
        this.userName = localStorage.getItem("UserName");

        this.iconTypeMenuTitle = this.navService.iconTypeMenuTitle;

        this.menuItemsSub = this.navService.menuItems$.subscribe(menuItem => {
            if (menuItem && menuItem instanceof Array && menuItem.length) {
                this.menuItems = menuItem;
                //Checks item list has any icon type.
                this.hasIconTypeMenuItem = !!this.menuItems.filter(
                    item => item.type === "icon"
                ).length;
                this.cdr.markForCheck();
                //  this.layoutConf = this.layout ? this.layout.layoutConf : null;
            }
            //  this.layoutConf = this.layout ? this.layout.layoutConf : null;
        });
        this.layoutConf = this.layout ? this.layout.layoutConf : null;
        this.navService.broadCastMenuReload();
    }

    ngAfterViewInit() { }

    ngOnDestroy() {
        if (this.menuItemsSub) {
            this.menuItemsSub.unsubscribe();
        }
    }

    toggleCollapse() {
        if (
            this.layoutConf.sidebarCompactToggle
        ) {
            this.layout.publishLayoutChange({
                sidebarCompactToggle: false
            });
        } else {
            this.layout.publishLayoutChange({
                // sidebarStyle: "compact",
                sidebarCompactToggle: true
            });
        }
    }

    public BaseRoute() {
        this.router.navigate(['home/dashboard']);
    }
}
