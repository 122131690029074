
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { appSettings } from '../../appsettings'
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

interface IMenuItem {
  type: string; // Possible values: link/dropDown/icon/separator/extLink
  name?: string; // Used as display text for item and title for separator type
  state?: string; // Router state
  icon?: string; // Material icon name
  tooltip?: string; // Tooltip text
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  sub?: IChildItem[]; // Dropdown items
  badges?: IBadge[];
}
interface IChildItem {
  type?: string;
  name: string; // Display text
  state?: string; // Router state
  icon?: string;
  sub?: IChildItem[];
}

interface IBadge {
  color: string; // primary/accent/warn/hex color codes(#fff000)
  value: string; // Display text
}

@Injectable()
export class NavigationService {
    constructor(public http: HttpClient, private appSettings: appSettings) { }
  iconMenu: IMenuItem[] = [];

  iconTypeMenuTitle: string = "Quick Access"; //"Frequently Accessed";
  // sets iconMenu as default;
  menuItems = new BehaviorSubject<IMenuItem[]>(this.iconMenu);
  // navigation component has subscribed to this Observable
  menuItems$ = this.menuItems.asObservable();


  public GetUserAllowedMenu(): Observable<any> {
      return this.http.get(this.appSettings.API_Config + 'Common/GetUserAllowedMenu')
  }


  public broadCastMenuReload() {
      this.iconMenu = [];
      this.GetUserAllowedMenu().subscribe(res => {
          var data = res.Data;
          var FinalResult = [];
          var parentMenus = data.filter(function (item) {
              return item.ParentMenu == undefined || item.ParentMenu == null || item.ParentMenu == '';
          });
          for (var x = 0; x < parentMenus.length; x++) {
              var item = {};
              item["name"] = parentMenus[x].MenuName;
              item["icon"] = parentMenus[x].MenuIcon;
              item["type"] = parentMenus[x].MenuTypeDisplay;
              item["tooltip"] = parentMenus[x].ToolTip;
              
              var childs = this.AddMenuChildren(parentMenus[x].Code, data); //recursion hai bhaaya.

              if (childs.length > 0) {
                  item["sub"] = childs;
              }
              else {
                  item["state"] = parentMenus[x].ActionURL;
              }
              this.iconMenu.push(JSON.parse(JSON.stringify(item)));
          }
          this.menuItems.next(this.iconMenu);
      });
  }


  public AddMenuChildren(MenuCode, AllData) {
      var RtnArray = [];
      var MenuChilds = AllData.filter(function (child) { return child.ParentMenu == MenuCode; });
      if (MenuChilds && MenuChilds.length > 0) {
          for (var x = 0; x < MenuChilds.length; x++) {
              var item = {};
              item["name"] = MenuChilds[x].MenuName;
              item["icon"] = MenuChilds[x].MenuIcon;
              item["type"] = MenuChilds[x].MenuTypeDisplay;
              item["tooltip"] = MenuChilds[x].ToolTip;
              //item["openInNewTab"] = MenuChilds[x].OpenInNewTab;
              //item["priority"] = MenuChilds[x].MenuOrder;
              //item["badge"] = '';
              //item["id"] = MenuChilds[x].MenuName;
              //item["LinkType"] = MenuChilds[x].LinkType;
              //item["DialogServiceName"] = MenuChilds[x].DialogServiceName;
              //item["DialogMethodName"] = MenuChilds[x].DialogMethodName;
              //item["parameter"] = MenuChilds[x].Params
              var childs = this.AddMenuChildren(MenuChilds[x].Code, AllData);
              if (childs.length > 0) { //if has children then dropdown else link.
                //  item["type"] = 'dropdown';
                  item["sub"] = childs;
              }
              else {
               //   item["type"] = 'link';
                  item["state"] = MenuChilds[x].ActionURL;
              }
              RtnArray.push(item);
          }
      }
      return RtnArray;
  }
  

  // Customizer component uses this method to change menu.
  // You can remove this method and customizer component.
  // Or you can customize this method to supply different menu for
  // different user type.
  publishNavigationChange(menuType: string) {
    switch (menuType) {
      //case "separator-menu":
      //  this.menuItems.next(this.separatorMenu);
      //  break;
      case "icon-menu":
        this.menuItems.next(this.iconMenu);
        break;
      //default:
      //  this.menuItems.next(this.plainMenu);
    }
  }
}
