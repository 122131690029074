
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FontSizeDirective } from './font-size.directive';
import { ScrollToDirective } from './scroll-to.directive';
import { AppDropdownDirective } from './dropdown.directive';
import { DropdownAnchorDirective } from './dropdown-anchor.directive';
import { DropdownLinkDirective } from './dropdown-link.directive';
import { EgretSideNavToggleDirective } from './egret-side-nav-toggle.directive';
import { EgretSidenavHelperDirective, EgretSidenavTogglerDirective } from './egret-sidenav-helper/egret-sidenav-helper.directive';
import { EgretHighlightDirective } from './egret-highlight.directive';

import { CapitalaiDirective } from '../../views/common/directive/capitalai.directive';
import { CustomMaxDirective } from '../../views/common/directive/custom-max-validator.directive';
import { CustomMinDirective } from '../../views/common/directive/custom-min-validator.directive';
import { EqualValidator } from '../../views/common/directive/equalValidator.directive';
import { OnlyNumber } from '../../views/common/directive/onlyNumber.directive';
import { UppercaseinpDirective } from '../../views/common/directive/uppercaseinp.directive';


const directives = [
    FontSizeDirective,
    ScrollToDirective,
    AppDropdownDirective,
    DropdownAnchorDirective,
    DropdownLinkDirective,
    EgretSideNavToggleDirective,
    EgretSidenavHelperDirective,
    EgretSidenavTogglerDirective,
    EgretHighlightDirective,
    CapitalaiDirective,
    CustomMaxDirective,
    CustomMinDirective,
    EqualValidator,
    OnlyNumber,
    UppercaseinpDirective
]

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: directives,
    exports: directives
})
export class SharedDirectivesModule { }