import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { HttpClientModule } from '@angular/common/http';
import { MatFormFieldModule } from '@angular/material'
 
import {
    MatInputModule,
    MatIconModule,
    MatCardModule,
    MatMenuModule,
    MatButtonModule,
    MatChipsModule,
    MatListModule,
    MatTooltipModule,
    MatDialogModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatToolbarModule,
    MatPaginatorModule,
    MatTabsModule,
    MatButtonToggleModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatProgressBarModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatTableModule,
    MatSortModule,
    MatBadgeModule,
    MatDividerModule,
    MatStepperModule
} from '@angular/material';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { DocViewerComponent } from './component/doc-viewer/doc-viewer.component';
import { UnionListComponent } from './component/union-list/union-list.component';

@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        PerfectScrollbarModule,
        CommonModule,
        MatInputModule,
        MatIconModule,
        MatCardModule,
        MatMenuModule,
        MatButtonModule,
        MatChipsModule,
        MatListModule,
        MatTooltipModule,
        MatDialogModule,
        MatSnackBarModule,
        MatSlideToggleModule,
        MatToolbarModule,
        MatPaginatorModule,
        MatTabsModule,
        MatButtonToggleModule,
        DragDropModule,
        FlexLayoutModule,
        TranslateModule,
        MatSelectModule,
        OwlDateTimeModule,
        MatAutocompleteModule,
        OwlNativeDateTimeModule,
        MatExpansionModule,
        NgxDatatableModule,
        MatProgressBarModule,
        MatCheckboxModule,
        MatFormFieldModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory
        }),
        MatDividerModule,
        MatStepperModule
    ],
    providers: [],
    entryComponents: [UnionListComponent],
    declarations: [UnionListComponent]
})
export class CommonMeepModule { }
