import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
    name: 'INRCurrency'
})
export class CurrencyPipe implements PipeTransform {

    transform(value2: number, showdecimal: any): string {
        if (value2) {
            let value: any = (Math.round(value2 * Math.pow(10, 2)) / Math.pow(10, 2)).toFixed(2);
            var result = value.toString().split('.');
            var lastThree = result[0].substring(result[0].length - 3);
            var otherNumbers = result[0].substring(0, result[0].length - 3);
            if (otherNumbers != '' && otherNumbers != '-')
                lastThree = ',' + lastThree;
            var output = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;

            if (result.length > 1) {
                if (showdecimal) {
                    output += "." + result[1];
                } 
            }
            if (output == "0.00") {
                return "";
            } else {
                return "" + output
            }
        } else {
            return value2 == 0 ? "0" : "";
        }

    }
   
}
