import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './shared/components/layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { AuthGuard } from './shared/services/auth/auth.guard';

export const rootRouterConfig: Routes = [
    {
        path: '',
        redirectTo: 'sessions/signin',
        pathMatch: 'full'
    },
    {
        path: '',
        component: AuthLayoutComponent,
        children: [
            {
                path: 'sessions',
                loadChildren: () => import('./views/sessions/sessions.module').then(m => m.SessionsModule),
                data: { title: 'Session' }
            }
        ]
    },
    {
        path: '',
        component: AdminLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'masters',
                loadChildren: () => import('./views/masters/masters.module').then(m => m.MastersModule),
                data: { title: 'Masters', breadcrumb: 'Masters' }
            }
        ]
    },
    {
        path: '',
        component: AdminLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'accessControl',
                loadChildren: () => import('./views/access-control/access-control.module').then(m => m.AccessControlModule),
                data: { title: 'Access Control', breadcrumb: 'Access Control' }
            }
        ]
    },
    {
        path: '',
        component: AdminLayoutComponent,
        children: [
            {
                path: 'home',
                loadChildren: () => import('./views/home/home.module').then(m => m.HomeModule),
                data: { title: 'Home', breadcrumb: 'Home' }
            }
        ]
    },
    {
        path: '**',
        redirectTo: 'sessions/404'
    }
];

