import { Injectable } from '@angular/core';
 
@Injectable()
export class appSettings {
    //BasePath: string = 'http://localhost:4200/'
    //API_Config: string = 'http://localhost:61427/api/';
    //AttachmentDownloadPath: string = 'http://localhost:61427/FileUpload/'
    //AttachmentDownloadPath1: string = 'http://localhost:61427/FileUpload/ImgeFile/'

    //BasePath: string = 'http://mindtechsolutions.com/tester/Meep/MeepAPI/'
    //API_Config: string = 'http://mindtechsolutions.com/tester/Meep/MeepAPI/api/';
    //AttachmentDownloadPath: string = 'http://mindtechsolutions.com/tester/Meep/MeepAPI/FileUpload/'
    //AttachmentDownloadPath1: string = 'http://mindtechsolutions.com/tester/Meep/MeepAPI/FileUpload/ImgeFile/'

    BasePath: string = 'https://mddashboard.amulamcs.in/API/'
    API_Config: string = 'https://mddashboard.amulamcs.in/API/api/';
    AttachmentDownloadPath: string = 'https://mddashboard.amulamcs.in/API/FileUpload/'
    AttachmentDownloadPath1: string = 'https://mddashboard.amulamcs.in/API/FileUpload/ImgeFile/'
}