import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
    selector: '[ngModel]'
})
export class CapitalaiDirective {
    @Output() ngModelChange: EventEmitter<any> = new EventEmitter();
    value: any;

    @HostListener('input', ['$event']) onInputChange($event) {
        if (!$event.target.getAttribute("appUppercaseinp")) {
            var FinalNewValue = "";
            var InputVal = $event.target.value;
            if (InputVal.length > 1) {
                var RemovedLastChar = InputVal ? InputVal.substring(0, InputVal.length - 1) : "";
                var lastCharacter = RemovedLastChar ? RemovedLastChar.slice(-1) : ""
                if (lastCharacter == " ") {
                    FinalNewValue = RemovedLastChar + $event.target.value.slice(-1).toUpperCase()
                } else {
                    FinalNewValue = $event.target.value
                }
            } else {
                FinalNewValue = $event.target.value.toUpperCase()
            }
            this.value = FinalNewValue;
            this.ngModelChange.emit(this.value);
        }
    }
}
