import { Injectable } from '@angular/core';
import { Observable, interval, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { appSettings } from '../../../appsettings';
import { map } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class CommonService {
    public controllerName: string = "Common"

    constructor(
        public http: HttpClient,
        public appSettings: appSettings
    ) { }


    public IsUserLoggedIn() {
        return localStorage.getItem("x-access-token") ? true : false;
    }

    public textJSON(text) {
        //if (typeof text !== "string") {
        //    return false;
        //}
        if (typeof text === "boolean") {
            return text
        }
        try {
            return JSON.parse(text);
        }
        catch (error) {
            return false;
        }
    }

    public getStudentDetailData(studentData: any): Observable<any> {
        if (studentData) {
            return this.http.post(this.appSettings.API_Config + this.controllerName + '/GetStudentDetailInfo', studentData);
            //return this.http.get(this.controllerName + '/GetStudentDetailInfo', studentData);
        }
    }
}
