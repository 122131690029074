import { Component, OnInit, Inject, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { Subscription, of, Observable } from 'rxjs';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { UnopnListService } from './unopn-list.service';
import * as moment from 'moment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-union-list',
  templateUrl: './union-list.component.html',
  styleUrls: ['./union-list.component.scss'],
  providers: [UnopnListService]
})
export class UnionListComponent implements OnInit {

    public itemForm: FormGroup;
    public errorMessage: string = "";
    public UnionArray: any = [];

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private UnionListService: UnopnListService,
        public dialogRef: MatDialogRef<UnionListComponent>,
        private fb: FormBuilder,
        private router: Router
    ) { }

    ngOnInit() {
        this.GetUnionList();
    }


    GetUnionList() {
        this.UnionListService.GetUnion().subscribe(RtnData => {
            this.UnionArray = RtnData;
            console.log(RtnData);
        })
    }

    ReinitToken(UnionObj) {
        this.UnionListService.ReinitToken(UnionObj).subscribe(RtnData => {
            if (RtnData) {
                sessionStorage.setItem("x-access-token", RtnData['Token']);
                sessionStorage.setItem("UnionName", RtnData['UnionName']);

                this.router.navigate(['/home/dashboard']);
                this.dialogRef.close();
            }
        })
    }

}
