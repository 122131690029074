import { Injectable } from '@angular/core';
import { appSettings } from '../../../appsettings';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';


@Injectable(
    {
        providedIn: 'root'
    }
)
 export class SigninService {
    public controllerName: string = "signin";
    
    constructor(
        public http: HttpClient,
        private appSettings: appSettings
    ) { }

    public submitSignin(item: any) {
        if (item) {
            return this.http.post(this.appSettings.API_Config + this.controllerName + '/AuthenticateUser', item);
        }
    }

    public ReinitToken(item: any) {
        if (item) {
            return this.http.post(this.appSettings.API_Config + this.controllerName + '/ReinitToken', item);
        }
    }
}
