import { NgModule, ErrorHandler } from '@angular/core';

import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GestureConfig, MatRippleModule } from '@angular/material';
import {PerfectScrollbarModule,PERFECT_SCROLLBAR_CONFIG,PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';

 
import { RouterModule } from '@angular/router'; 
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { HTTP_INTERCEPTORS,HttpClient, HttpClientModule } from '@angular/common/http';

import { SharedModule } from './shared/shared.module';
 
import { rootRouterConfig } from './app.routing'; 
import { ErrorHandlerService } from './shared/services/error-handler.service';

import { AppComponent } from './app.component';
import { NgxQRCodeModule } from 'ngx-qrcode2';
import { AgmCoreModule } from '@agm/core';

import { appSettings } from './appsettings'
import { TokenInterceptor } from './views/sessions/token.interceptor';
import { OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';

import { GoogleChartsModule } from 'angular-google-charts';

export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient);
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};

@NgModule({
  declarations: [
      AppComponent
      
  ],
  imports: [
      BrowserModule,
      BrowserAnimationsModule,
      HttpClientModule,
      PerfectScrollbarModule,
      SharedModule,
      NgxQRCodeModule,
      MatRippleModule,
      AgmCoreModule.forRoot({
        apiKey: 'AIzaSyA_6Tm_itd6GUodUVxK0UulvQA11cONTA0',
        libraries: ['places']
      }),
      TranslateModule.forRoot({
          loader: {
              provide: TranslateLoader,
              useFactory: HttpLoaderFactory,
              deps: [HttpClient]
          }
      }),
      RouterModule.forRoot(rootRouterConfig, { useHash: true }),
      GoogleChartsModule
  ],
  providers: [
      { provide: ErrorHandler, useClass: ErrorHandlerService },
      { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },
      { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
      { provide: HTTP_INTERCEPTORS,useClass: TokenInterceptor,multi: true},
      appSettings,
      { provide: OWL_DATE_TIME_LOCALE, useValue: 'en-SG' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
